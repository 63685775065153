import { Avatar, CircularProgress, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import useCache, { IMAGE_ORTHOPHOTO, IMAGE_SIZE_THUMBNAIL } from "../hooks/useCache";
import AnalysisLink from "./AnalysisLink";

export interface AnalysisRowProps {
  analysis: {
    uuid: string;
    created_at: string;
    state: string;
    agriculture_analysis_data: {
      orthophoto_png: string,
    },
    field: {
      name: string;
      uuid: string;
    }
  }
  prefix: string;
}

function AnalysisRow(props: AnalysisRowProps) {
  const [ imageURL, setImageURL ] = React.useState<string | undefined>();
  const { cacheInitialized, getPresignedUrl, progress } = useCache();

  React.useEffect(() => {
    if (cacheInitialized && props.analysis) {
      getPresignedUrl(props.analysis.uuid, IMAGE_ORTHOPHOTO, IMAGE_SIZE_THUMBNAIL).then((url) => {
        setImageURL(url);
      }).catch((e) => {
        console.error("Failed to download presigned image", e);
      });
    }
  }, [cacheInitialized]);

  return (
    <AnalysisLink analysis={props.analysis} prefix={props.prefix}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          { (props.analysis.state === 'running' || !imageURL) && <div style={{
            borderRadius: 10,
            height: 50,
            width: 50,
            backgroundColor: 'var(--gray-2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <CircularProgress size={30}
              variant={progress > 0 ? 'determinate' : 'indeterminate'}
              value={progress > 0 ? progress * 100 : undefined} />
          </div> }
          { props.analysis.state !== 'running' && imageURL && <Avatar src={imageURL} variant="rounded" style={{
            borderRadius: 10,
            height: 50,
            width: 50,
          }} /> }
        </ListItemAvatar>
        <ListItemText
          primary={new Date(props.analysis.created_at).toLocaleDateString() + (props.analysis.field ? (' - ' + props.analysis.field.name) : '')}
          secondary={
            <React.Fragment>
              {props.analysis.state}
            </React.Fragment>
          }
        />
      </ListItem>
    </AnalysisLink>
  );
}

export default AnalysisRow;
