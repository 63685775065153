import React from "react";

export interface AudioRecorderProps {
  onRecord: (blob: Blob) => void;
}

function useAudioRecorder(props: AudioRecorderProps) {
  const [isRecording, setIsRecording] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [audioBlob, setAudioBlob] = React.useState<Blob | null>(null);
  const [mediaRecorder, setMediaRecorder] = React.useState<MediaRecorder | null>(null);

  const start = () => {
    setError(null);
    navigator.mediaDevices.getUserMedia({ audio: true } as MediaStreamConstraints)
    .then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      const audioBlobs: Blob[] = [];
      mediaRecorder.addEventListener("dataavailable", event => {
        audioBlobs.push(event.data);
      });
      mediaRecorder.start();

      mediaRecorder.addEventListener("stop", () => {
        // create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
        const mimeType = mediaRecorder.mimeType;
        const audioBlob = new Blob(audioBlobs, { type: mimeType });
        setAudioBlob(audioBlob);
        props.onRecord(audioBlob);
      });

      setMediaRecorder(mediaRecorder);
      setIsRecording(true);
    })
    .catch((err) => {
      setError(err.message);
    });
  };

  const stop = () => {
    setIsRecording(false);
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  const cancel = () => {
    setIsRecording(false);
    setAudioBlob(null);
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  }

  const reset = () => {
    setIsRecording(false);
    setAudioBlob(null);
    setError(null);
  }

  const isAudioRecorderSupported = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  return { start, stop, cancel, reset, isAudioRecorderSupported, isRecording, error, audioBlob };
}

export default useAudioRecorder;
