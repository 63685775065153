import { Marker } from "@vis.gl/react-google-maps";
import React from "react";

export interface LabelMarkerProps {
  position: google.maps.LatLngLiteral;
  label: string;
}

function LabelMarker(props: LabelMarkerProps) {
  return (
    <React.Fragment>
      <Marker
        position={props.position}
        label={{
          className: 'distance-marker-label',
          text: props.label,
          color: 'white',
        }}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 0,
        }}
      />
    </React.Fragment>
  );
}

export default LabelMarker;
