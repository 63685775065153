import { Fab } from '@mui/material';
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

function BackButton() {
  const navigate = useNavigate();

  const style: React.CSSProperties = {
    position: 'absolute',
    top: 8,
    left: 8,
  };

  return (
    <Fab
      onClick={() => {
        navigate(-1);
      }}
      style={style}>
      <ArrowBackIosNewIcon />
    </Fab>
  );
}

export default BackButton;
