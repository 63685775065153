import { Chip } from "@mui/material";
import React from "react";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

export interface AudioClipProps {
  text: string;
  audioBlob?: Blob;
  audioURL?: string;
  onDeleted?: () => void;
}

function AudioClip(props: AudioClipProps) {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [audio, setAudio] = React.useState<HTMLAudioElement | null>(null);

  const getURL = () => {
    if (props.audioBlob) {
      return URL.createObjectURL(props.audioBlob);
    } else if (props.audioURL) {
      return props.audioURL;
    } else {
      return null;
    }
  }

  const handleClick = () => {
    if (isPlaying) {
      audio?.pause();
    } else {
      const url = getURL();
      if (!url) {
        console.error("No audio source found");
        return;
      }

      const audio = new Audio(url);
      audio.play();
      audio.addEventListener("ended", () => {
        setIsPlaying(false);
      });
      setAudio(audio);
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <Chip
      icon={isPlaying ? <StopIcon /> : <PlayArrowIcon />}
      label={props.text}
      onClick={handleClick}
      onDelete={props.onDeleted}
      style={{
        margin: '0.25rem',
      }}
    />
  );
}

export default AudioClip;
