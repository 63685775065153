import React from 'react';
import GeoTIFFLayer, { GeoTIFFLayerProps } from './GeoTIFFLayer';
import useCache, { IMAGE_KEY, IMAGE_SIZE_MEDIUM } from '../hooks/useCache';
import PNGLayer from './PNGLayer';
import { db } from '../db/db';
import Loading from './Loading';

export interface AnalysisLayerProps extends GeoTIFFLayerProps {
  analysisID?: string;
  image: IMAGE_KEY;
  png?: boolean;
  reversed?: boolean;
}

function AnalysisLayer(props: AnalysisLayerProps) {
  const [ blob, setBlob ] = React.useState<Blob | undefined>();
  const [ bounds, setBounds ] = React.useState<google.maps.LatLngBounds | undefined>();

  const { cacheInitialized, downloadPresigned, progress } = useCache();

  React.useEffect(() => {
    if (!props.analysisID) {
      return;
    }

    db.analyses.get(props.analysisID).then((analysis) => {
      if (analysis) {
        setBounds(new google.maps.LatLngBounds(analysis.bounds));
      }
      // TODO: if the analysis is not found, look for the bounds of teh field
    });
  }, [props.analysisID, props.png]);

  React.useEffect(() => {
    if (!cacheInitialized || !props.analysisID) {
      return;
    }

    downloadPresigned(props.analysisID, props.image, IMAGE_SIZE_MEDIUM).then((blob) => {
      setBlob(blob);
    }).catch((e) => {
      console.error("Failed to download presigned image", e);
      setBlob(undefined);
      setBounds(undefined);
    });
  }, [props.analysisID, cacheInitialized]);

  if (!blob) {
    return (
      <Loading open={true} {...props}
      progress={progress}
      onCancel={() => {
        // jump back in history
        window.history.back();
      }} />
    );
  }

  if (props.png) {
    return (
      <PNGLayer blob={blob} bounds={bounds} {...props} />
    );
  }

  return (
    <GeoTIFFLayer blob={blob} {...props} />
  );
}

export default AnalysisLayer;
