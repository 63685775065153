import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import FieldMenu from "./FieldMenu";
import useEmbed from "../common/hooks/useEmbed";

export interface FieldsGalleryProps {
  fields: {
    uuid: string;
    name: string;
  }[];
}

function FieldsGallery(props: FieldsGalleryProps) {
  const { t } = useTranslation();
  const { appendParams } = useEmbed();

  return (
    <React.Fragment>
      <Box style={{
        margin: 20,
        marginBottom: 0,
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: 20,
        }}>
          <span>{t('Inspections')}</span>
          <span>
            <Link to={appendParams('/app/inspections')}><Button>{t('See All')}</Button></Link>
          </span>
        </div>
      </Box>
      <div style={{
        display: 'flex',
        overflowX: 'auto',
        scrollbarWidth: 'none',
        paddingLeft: 20,
        paddingRight: 20,
      }}>
        {props.fields.map(field => <React.Fragment key={field.uuid}>
          <FieldsGalleryItem field={field} />
        </React.Fragment>)}
      </div>
    </React.Fragment>
  );
}

function FieldsGalleryItem(props: { field: { uuid: string; name: string; }; }) {
  const [ fieldName, setFieldName ] = React.useState<string | undefined>(props.field.name);

  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  return (
    <Paper elevation={0}
      onClick={() => {
        navigate(appendParams(`/app/inspections/${props.field.uuid}`));
      }}
      style={{
        margin: 5,
        padding: 20,
        backgroundImage: 'url("/imgs/field_background.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'var(--white)',
        borderRadius: 20,
        height: 120,
        position: 'relative',
      }}>
      <Typography variant="h6" className="truncate" style={{
        width: 100,
      }}>{fieldName}</Typography>
      <div style={{
        top: 10,
        right: 10,
        position: 'absolute',
      }}>
        <FieldMenu uuid={props.field.uuid} buttonStyle={{
          color: 'var(--white)',
        }} rename={(name) => {
          if (name) {
            setFieldName(name);
          }
        }} />
      </div>
    </Paper>
  );
}

export default FieldsGallery;
