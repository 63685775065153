import React from "react";
//import * as OV from "online-3d-viewer";
import useCache, { IMAGE_KEY } from "../hooks/useCache";

export interface ViewerWithUrlsProps {
  analysisID: string;
  image: IMAGE_KEY;
}

function Viewer3D(props: ViewerWithUrlsProps) {
  const [ url, setUrl ] = React.useState<string | null>(null);

  const { getPresignedUrl } = useCache();

  React.useEffect(() => {
    getPresignedUrl(props.analysisID, props.image).then((url) => {
      setUrl(url);
    });
  }, [props.image]);

  if (!url) {
    return null;
  }

  return (
    <iframe style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: "none",
    }} src={'https://3dviewer.net/embed.html#model=' + url} />
  );

  // const parentDiv = React.useRef<HTMLDivElement>(null);

  // const [ viewerRef, setViewerRef ] = React.useState<OV.EmbeddedViewer | null>(null);

  // React.useEffect(() => {
  //   if (!url) {
  //     return;
  //   }
  //   if (!parentDiv || !parentDiv.current) {
  //     return;
  //   }

  //   OV.SetExternalLibLocation("libs");
  //   OV.Init3DViewerElements(() => {});
  //   if (viewerRef === null) {
  //     let viewer = new OV.EmbeddedViewer(parentDiv.current, {
  //       defaultColor: new OV.RGBColor(200, 200, 200),
  //       backgroundColor: new OV.RGBAColor(255, 255, 255, 255),
  //       defaultLineColor: new OV.RGBColor(0, 0, 0),
  //       edgeSettings: new OV.EdgeSettings(false, new OV.RGBColor(0, 0, 0), 1),
  //       environmentSettings: new OV.EnvironmentSettings([], false),
  //       onModelLoaded: () => {
  //         // const nMat = viewer.GetModel().MaterialCount();
  //         // console.log(nMat);
  //         // for (let i = 0; i < nMat; i++) {
  //         //   const mat: OV.PhysicalMaterial = viewer.GetModel().GetMaterial(i);
  //         // }
  //         // console.log(viewer.GetModel().MeshCount());
  //         viewer.GetViewer().SetUpVector(OV.Direction.Z, true);
  //         viewer.GetViewer().SetNavigationMode(OV.NavigationMode.FixedUpVector);
  //         viewer.GetViewer().SetMeshesVisibility(() => true);
  //       },
  //     });
  //     // viewer.Resize();

  //     let inputFiles = [
  //       new OV.InputFile("model.glb", OV.FileSource.Url, url),
  //     ];

  //     setViewerRef(viewer);

  //     // Then we just pass inputFiles into the below method and viola
  //     viewer.LoadModelFromInputFiles(inputFiles);
  //   }

  //   return () => {
  //     if (viewerRef !== null && parentDiv.current !== null) {
  //       delete viewerRef.model;
  //       viewerRef.viewer.renderer.resetState();
  //       viewerRef.viewer.Clear();
  //       //delete (viewerRef.current as any).viewer;
  //       const gl = viewerRef.canvas.getContext("webgl2");
  //       gl?.getExtension("WEBGL_lose_context")?.loseContext();
  //       const tempClone = viewerRef.canvas.cloneNode(true);
  //       viewerRef.canvas.parentNode?.replaceChild(
  //         tempClone,
  //         viewerRef.canvas
  //       );
  //       for (let i = 0; i < parentDiv.current.children.length; i++) {
  //         parentDiv.current.removeChild(parentDiv.current.children[i]);
  //       }
  //       // viewerRef.current.canvas.parentNode.removeChild(viewerRef.current.canvas);
  //       // viewerRef.current.canvas.remove()
  //       setViewerRef(null);
  //     }
  //   };
  // }, [url]);

  // return (
  //   <React.Fragment>
  //     <div
  //       ref={parentDiv}
  //       role={"img"}
  //       style={{
  //         width: "100%",
  //         height: "100%",
  //         position: "absolute",
  //         top: 0,
  //         left: 0,
  //         right: 0,
  //         bottom: 0,
  //       }}
  //     ></div>
  //   </React.Fragment>
  // );
};

export default Viewer3D;
