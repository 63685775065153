import React from "react";
import { Link } from "react-router-dom";
import useEmbed from "../hooks/useEmbed";

export interface AnalysisLinkProps extends React.PropsWithChildren<{}> {
  analysis: {
    uuid: string;
    field: {
      uuid: string;
    } | null;
  };
  prefix: string;
}

function AnalysisLink(props: AnalysisLinkProps) {
  const { appendParams } = useEmbed();

  if (!props.analysis.field || !props.analysis.field.uuid) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    );
  }

  return (
    <Link to={appendParams(props.prefix + props.analysis.field.uuid + '?analysis=' + props.analysis.uuid)}>
      {props.children}
    </Link>
  );
}

export default AnalysisLink;
