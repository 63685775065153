import { AppBar, Box, IconButton, Toolbar, Typography, styled } from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { LeftMenuContext } from "./LeftMenu";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}));

function TopBar() {
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  const leftMenuCtx = React.useContext(LeftMenuContext);

  return (
    <React.Fragment>
      <Offset style={{
        marginTop: 15,
      }} />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" style={{
          backgroundColor: 'var(--white)',
          color: 'var(--black)',
          boxShadow: 'none',
        }}>
          <StyledToolbar>
            <IconButton
              onClick={() => {leftMenuCtx?.setOpen(true)}}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              noWrap
              component="div"
              sx={{ flexGrow: 1, alignSelf: 'flex-end' }}
            ></Typography>
            <IconButton
              size="large"
              aria-label="notifications"
              edge="end"
              color="inherit"
            >
              <NotificationsNoneIcon />
            </IconButton>
          </StyledToolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}

export default TopBar;
