import { GLB_FILE, IMAGE_DSM_TIFF, IMAGE_DTM_TIFF, IMAGE_ORTHOPHOTO } from "../common/hooks/useCache";
import { Index } from "../common/utils/indexes";

export let globalAvailableIndexes: Index[] = [
  { id: 'None', name: 'No Index', description: null, showAlways: true },
  { id: 'Orthophoto', name: 'Orthophoto', description: 'Digital Reconstruction', imageKey: IMAGE_ORTHOPHOTO },
  // TODO: update descriptions and translations
  { id: 'DSM', name: 'DSM', description: 'Surface Model', imageKey: IMAGE_DSM_TIFF },
  { id: 'DTM', name: 'DTM', description: 'Terrain Model', imageKey: IMAGE_DTM_TIFF },
  { id: 'GLB', name: '3D', description: '3D Model', imageKey: GLB_FILE, noCompare: true }
];

export function getIndex(id?: string): Index | undefined {
  if (!id) {
    return undefined;
  }

  for (const index of globalAvailableIndexes) {
    if (index.id === id) {
      return index;
    }
  }
  return undefined;
}
