import React from 'react';
import { Outlet } from 'react-router-dom';
import LeftMenu from '../components/LeftMenu';
import TopBar from '../components/TopBar';
import BottomBar from '../components/BottomBar';
import useEmbed from '../common/hooks/useEmbed';

function Layout() {
  const { embed } = useEmbed();

  return (
    <React.Fragment>
      { !embed && <TopBar /> }
      <Outlet />
      { !embed && <BottomBar /> }
      { !embed && <LeftMenu /> }
    </React.Fragment>
  );
}

export default Layout;
