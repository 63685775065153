import { Marker } from "@vis.gl/react-google-maps";
import React from "react";
import Polyline from "../common/components/Polyline";
import Polygon from "../common/components/Polygon";
import LabelMarker from "../common/components/LabelMarker";
import { numberFormat } from "../common/utils/text";

export interface MeasureProps {
  positions: google.maps.LatLngLiteral[];
}

function Measure(props: MeasureProps) {
  const [ closedPositions, setClosedPositions ] = React.useState<google.maps.LatLngLiteral[]>([]);

  const [ distances, setDistances ] = React.useState<number[]>([]);
  const [ area, setArea ] = React.useState<number | null>(null);

  const areaHectares = React.useMemo(() => {
    if (area === null) {
      return null;
    }
    return area / 10000;
  }, [area]);

  const center = React.useMemo(() => {
    if (closedPositions.length === 0) {
      return null;
    }

    const lat = closedPositions.reduce((a, c) => a + c.lat, 0) / closedPositions.length;
    const lng = closedPositions.reduce((a, c) => a + c.lng, 0) / closedPositions.length;
    return {
      lat,
      lng,
    };
  }, [closedPositions]);

  React.useEffect(() => {
    if (props.positions.length === 0) {
      setClosedPositions([]);
      return;
    }

    if (props.positions[0].lat === props.positions[props.positions.length - 1].lat &&
        props.positions[0].lng === props.positions[props.positions.length - 1].lng) {
      setClosedPositions(props.positions);
    } else {
      setClosedPositions([...props.positions, props.positions[0]]);
    }
  }, [props.positions]);

  React.useEffect(() => {
    if (closedPositions.length < 2) {
      setDistances([]);
      return;
    }

    const d: number[] = [];
    for (let i = 0; i < closedPositions.length - 1; i++) {
      const p1 = closedPositions[i];
      const p2 = closedPositions[i + 1];
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(p1.lat, p1.lng),
        new google.maps.LatLng(p2.lat, p2.lng)
      );
      d.push(distance);
    }
    setDistances(d);
  }, [closedPositions]);

  React.useEffect(() => {
    if (closedPositions.length < 3) {
      setArea(null);
      return;
    }

    const a = google.maps.geometry.spherical.computeArea(
      closedPositions.map(p => new google.maps.LatLng(p.lat, p.lng))
    );
    setArea(a);
  }, [closedPositions]);

  return (
    <React.Fragment>
      { closedPositions.map((position, index) => {
        return (
          <React.Fragment key={index}>
            <Marker position={position} />
          </React.Fragment>
        );
      })}
      { closedPositions.length >= 2 ?
        closedPositions.map((position, index) => {
          if (index === closedPositions.length - 1) {
            return null;
          }
          return (
            <LabelMarker
              key={index}
              position={
                new google.maps.LatLng(
                  (position.lat + closedPositions[(index + 1) % closedPositions.length].lat) / 2,
                  (position.lng + closedPositions[(index + 1) % closedPositions.length].lng) / 2
                ).toJSON()
              }
              label={numberFormat(distances[index], 2) + ' m'}
            />
          );
        }) : null }
      { closedPositions.length > 1 && (
        <Polyline
          path={closedPositions}
          strokeColor="#ff0000"
        />
      )}
      { props.positions.length >= 3 && center && areaHectares && (
        <LabelMarker
          position={center}
          label={numberFormat(areaHectares, 2) + ' ha'}
        />
      )}
      { props.positions.length >= 3 && (
        <Polygon
          path={closedPositions}
          transparent
        />
      )}
    </React.Fragment>
  );
}

export default Measure;
