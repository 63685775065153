import { Avatar, CircularProgress, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import useCache, { IMAGE_ORTHOPHOTO, IMAGE_SIZE_THUMBNAIL } from "../common/hooks/useCache";
import FieldMenu from "./FieldMenu";
import { useNavigate } from "react-router-dom";
import useEmbed from "../common/hooks/useEmbed";

export interface FieldRowProps {
  field: {
    uuid: string,
    name: string,
    analyses?: {
      uuid: string,
      agriculture_analysis_data: {
        orthophoto_png: string,
      },
    }[],
  };
}

function FieldRow(props: FieldRowProps) {
  const [ fieldName, setFieldName ] = React.useState<string | undefined>(props.field.name);
  const [ imageURL, setImageURL ] = React.useState<string | undefined>();
  const { cacheInitialized, getPresignedUrl, progress } = useCache();

  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  React.useEffect(() => {
    if (cacheInitialized && props.field.analyses && props.field.analyses.length > 0) {
      getPresignedUrl(props.field.analyses[0].uuid, IMAGE_ORTHOPHOTO, IMAGE_SIZE_THUMBNAIL).then((url) => {
        setImageURL(url);
      }).catch((e) => {
        console.error("Failed to download presigned image", e);
      });
    }
  }, [cacheInitialized]);

  return (
    <ListItem alignItems="flex-start"
      onClick={() => {
        navigate(appendParams(`/app/inspections/${props.field.uuid}`));
      }}
      secondaryAction={
        <FieldMenu uuid={props.field.uuid} rename={(name) => {
          if (name) {
            setFieldName(name);
          }
        }} />
      }>
      <ListItemAvatar>
        { !imageURL && <div style={{
          borderRadius: 10,
          height: 50,
          width: 50,
          backgroundColor: 'var(--gray-2)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CircularProgress size={30}
            variant={progress > 0 ? 'determinate' : 'indeterminate'}
            value={progress > 0 ? progress * 100 : undefined} />
        </div> }
        { imageURL && <Avatar src={imageURL} variant="rounded" style={{
          borderRadius: 10,
          height: 50,
          width: 50,
        }} /> }
      </ListItemAvatar>
      <ListItemText
        primary={fieldName}
        secondary={
          <React.Fragment>
            { /* TODO: details */ }
          </React.Fragment>
        }
      />
    </ListItem>
  );
}

export default FieldRow;
