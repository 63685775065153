import { Card, CardActions, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from "react-i18next";
import TimeLineCard from "../common/components/TimeLineCard";

export interface TimeLineProps {
  analysis: {
    uuid: string;
    created_at: string;
    agriculture_analysis_data: {
      orthophoto_png: string,
    },
  }[];
  currentAnalysis?: {
    uuid: string;
    created_at: string;
  };
  onAnalysisSelected?: (analysisUuid: string) => void;
}

function TimeLine(props: TimeLineProps) {
  const [ collapsed, setCollapsed ] = React.useState<boolean>(true);

  const { t } = useTranslation();

  const style: React.CSSProperties = {
    position: 'absolute',
    bottom: 24,
    left: 8,
    right: 8,
    height: collapsed ? 56 : 216,
  };

  return (
    <Card style={style}>
      { !collapsed &&
      <CardContent style={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 0,
        paddingRight: 0,
      }}>
        <div style={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          paddingLeft: 20,
          paddingRight: 20,
          height: 150,
        }}>
          {props.analysis.map((ana) => <React.Fragment key={ana.uuid}>
            <TimeLineCard analysis={ana} onClick={() => {
              if (props.onAnalysisSelected) {
                props.onAnalysisSelected(ana.uuid);
              }
            }}/>
          </React.Fragment>)}
        </div>
      </CardContent>
      }
      <CardActions disableSpacing onClick={() => setCollapsed(!collapsed)}>
        <Typography variant="body1" style={{
          marginLeft: 20,
        }}>
          { props.currentAnalysis
          ? t('Monitoring date') + ': ' + new Date(props.currentAnalysis.created_at).toLocaleDateString()
          : t('No analysis selected') }
        </Typography>
        <IconButton
        style={{
          marginLeft: 'auto',
        }}>
          { collapsed && <ExpandMoreIcon /> }
          { !collapsed && <ExpandLessIcon /> }
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default TimeLine;
