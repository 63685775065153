import React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from "react-i18next";
import { Chip, IconButton, InputAdornment } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MicIcon from '@mui/icons-material/Mic';
import { numberFormat } from "../utils/text";
import useAudioRecorder from "../hooks/useAudioRecorder";
import AudioClip from "../components/AudioClip";
import ImageClip from "../components/ImageClip";
import { Annotation } from "../classes/annotation";
import { gql, useMutation } from "@apollo/client";
import useUser from "../hooks/useUser";
import useApi from "../hooks/useApi";

export interface AnnotateProps {
  fieldID: number;
  open: boolean;
  onClose: (annotation?: Annotation) => void;
  latLng: google.maps.LatLngLiteral | null;
}

function Annotate(props: AnnotateProps) {
  const [audioBlobs, setAudioBlobs] = React.useState<Blob[]>([]);
  const [pictureBlobs, setPictureBlobs] = React.useState<Blob[]>([]);

  const { t } = useTranslation();
  const { userID, organizationID } = useUser();
  const { post } = useApi();

  const [ insertAnnotations ] = useMutation(gql(`
    mutation InsertAnnotation($annotation: String!, $field_id: bigint!, $user_id: bigint!, $organization_id: bigint) {
      insert_annotations(objects: {
        annotation: $annotation,
        field_id: $field_id,
        user_id: $user_id,
        organization_id: $organization_id,
      }) {
        returning {
          id
          uuid
        }
      }
    }
  `));

  const [ insertLatLng ] = useMutation(gql(`
    mutation InsertLatLng($annotation_id: bigint!, $lat: numeric, $lng: numeric) {
      insert_lat_lngs(objects: {
        annotation_id: $annotation_id,
        lat: $lat,
        lng: $lng,
      }) {
        returning {
          id
        }
      }
    }
  `));

  const onClose = (annotation?: Annotation) => {
    props.onClose(annotation);
    setAudioBlobs([]);
    setPictureBlobs([]);
  }

  const pushAttachment = async (blob: Blob, extension: string, contentType: string, annotationUUID: string) => {
    const data = {
      contentType: contentType,
      extension: extension,
      size: blob.size,
      annotationId: {
        id: annotationUUID,
      },
    };
    const presigned: any = await post('/attachments', data);
    if (!presigned) {
      return;
    }

    await fetch(presigned.url, {
      method: 'PUT',
      body: blob,      
      headers: {
        'Content-Type': contentType,
        'Content-Length': blob.size.toString(),
        'x-amz-storage-class': 'GLACIER_IR',
      },
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClose={() => {onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const annotation: Annotation = {
              uuid: '',
              position: props.latLng!,
              annotation: formJson.annotation,
              audioBlobs: audioBlobs,
              pictureBlobs: pictureBlobs,
              date: new Date(),
              attachments: [],
            };

            const result = await insertAnnotations({
              variables: {
                annotation: formJson.annotation,
                field_id: props.fieldID,
                user_id: userID,
                organization_id: organizationID,
              },
            });
            const id = result.data.insert_annotations.returning[0].id;
            annotation.uuid = result.data.insert_annotations.returning[0].uuid;
            if (props.latLng) {
              await insertLatLng({
                variables: {
                  annotation_id: id,
                  lat: props.latLng.lat,
                  lng: props.latLng.lng,
                },
              });
            }

            for (const audioBlob of audioBlobs) {
              await pushAttachment(audioBlob, 'webm', 'audio/webm', annotation.uuid);
            }

            for (const pictureBlob of pictureBlobs) {
              await pushAttachment(pictureBlob, 'jpg', 'image/jpeg', annotation.uuid);
            }

            onClose(annotation);
          },
        }}
      >
        <DialogTitle>{t('Annotate')}</DialogTitle>
        <DialogContent>
          <div>
            <Chip
              label={t('Lat') + ' ' + numberFormat(props.latLng?.lat, 4)}
              style={{
                margin: '0.25rem',
              }}
            />
            <Chip
              label={t('Long') + ' ' + numberFormat(props.latLng?.lng, 4)}
              style={{
                margin: '0.25rem',
              }}
            />
            {audioBlobs.map((audioBlob, index) => {
              return <AudioClip key={index} audioBlob={audioBlob} onDeleted={
                () => {setAudioBlobs(audioBlobs.filter((blob, i) => i !== index))}
              } text={t('Audio') + ' ' + (index+1)} />;
            })}
            {pictureBlobs.map((pictureBlob, index) => {
              return <ImageClip key={index} imageBlob={pictureBlob} onDeleted={
                () => {setPictureBlobs(pictureBlobs.filter((blob, i) => i !== index))}
              } text={t('Picture') + ' ' + (index+1)} />;
            })}
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="annotation"
            name="annotation"
            label="Annotation"
            type="text"
            fullWidth
            variant="standard"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <RecordAudio onRecord={(blob) => {
                  setAudioBlobs([...audioBlobs, blob]);
                }}/>
                <TakePicture onPicture={(blobs) => {
                  setPictureBlobs([...pictureBlobs, ...blobs]);
                }}/>
              </InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {onClose()}}>{t('Cancel')}</Button>
          <Button type="submit">{t('Annotate')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

interface RecordAudioProps {
  onRecord: (blob: Blob) => void;
}

function RecordAudio(props: RecordAudioProps) {
  const {
    start,
    stop,
    isAudioRecorderSupported,
    isRecording
  } = useAudioRecorder({
    onRecord: props.onRecord
  });

  if (!isAudioRecorderSupported()) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton
        edge="end"
        color={isRecording ? "primary" : "default"}
        onClick={() => {isRecording ? stop() : start()}}
      >
        <MicIcon />
      </IconButton>
    </React.Fragment>
  );
}

interface TakePictureProps {
  onPicture: (blobs: Blob[]) => void;
}

function TakePicture(props: TakePictureProps) {
  const onPictureChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const blobs = Array.from(event.target.files).map(file => new Blob([file]));
      props.onPicture(blobs);
    }
  }

  return (
    <React.Fragment>
      <input accept="image/jpg" id="icon-button-file" type="file" style={{display: 'none'}}
        onChange={onPictureChange} />
      <IconButton
        edge="end"
        onClick={() => {document.getElementById('icon-button-file')?.click()}}
      >
        <CameraAltIcon />
      </IconButton>
    </React.Fragment>
  );
}

export default Annotate;
