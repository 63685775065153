import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ShowImageProps {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  imageBlob?: Blob;
  imageURL?: string;
}

function ShowImage(props: ShowImageProps) {
  const [blobURL, setBlobURL] = React.useState<string | null>(null);

  const { t } = useTranslation();

  const handleClose = () => {
    props.setDialogOpen(false);
  };

  React.useEffect(() => {
    if (!props.imageBlob) {
      return;
    }

    const blobUrl = URL.createObjectURL(props.imageBlob);
    setBlobURL(blobUrl);
    return () => {
      URL.revokeObjectURL(blobUrl);
    }
  }, [props.imageBlob]);

  React.useEffect(() => {
    if (!props.imageURL) {
      return;
    }

    setBlobURL(props.imageURL);
  }, [props.imageURL]);

  if (!props.imageBlob && !props.imageURL) {
    console.error("No imageBlob or imageURL provided");
    return null;
  }

  if (!blobURL) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.dialogOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          {t('Picture')}
        </DialogTitle>
        <DialogContent>
          <img src={blobURL} width={'100%'} alt={t('Picture')} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ShowImage;
