import React from 'react';
import { Outlet } from 'react-router-dom';
import useEmbed from '../common/hooks/useEmbed';

function Layout() {
  const _ = useEmbed();

  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
}

export default Layout;
