import React from "react";
import { Marker } from "@vis.gl/react-google-maps";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Annotation } from "../classes/annotation";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../utils/text";
import AudioClip from "./AudioClip";
import ImageClip from "./ImageClip";
import AttachmentChip from "./AttachmentChip";

export interface AnnotationMarkerProps {
  annotation: Annotation;
  onDelete?: () => void;
}

function AnnotationMarker(props: AnnotationMarkerProps) {
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Marker position={props.annotation.position} onClick={handleClickOpen} />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          {t('Annotation')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.annotation.annotation}
          </DialogContentText>
          <div style={{
            marginTop: '1rem',
          }}>
            <Chip
              label={t('Lat') + ' ' + numberFormat(props.annotation.position.lat, 4)}
              style={{
                margin: '0.25rem',
              }}
            />
            <Chip
              label={t('Long') + ' ' + numberFormat(props.annotation.position.lng, 4)}
              style={{
                margin: '0.25rem',
              }}
            />
            <Chip
              label={props.annotation.date.toLocaleDateString()}
              style={{
                margin: '0.25rem',
              }}
            />
            {props.annotation.attachments.map((attachment, index) => {
              return <AttachmentChip key={index} attachment={attachment} index={index} />;
            })}
            {props.annotation.audioBlobs.map((audioBlob, index) => {
              return <AudioClip key={index} audioBlob={audioBlob} text={t('Audio') + ' ' + (index+1)} />;
            })}
            {props.annotation.pictureBlobs.map((pictureBlob, index) => {
              return <ImageClip key={index} imageBlob={pictureBlob} text={t('Picture') + ' ' + (index+1)} />;
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => {
            if (props.onDelete) {
              props.onDelete();
            }
            handleClose();
          }}>
            {t('Delete')}
          </Button>
          <Button onClick={handleClose} autoFocus>
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AnnotationMarker;
