import { Avatar, Chip } from "@mui/material";
import React from "react";
import ShowImage from "../dialogs/ShowImage";

export interface ImageClipProps {
  text: string;
  imageBlob?: Blob;
  imageURL?: string;
  onDeleted?: () => void;
}

function ImageClip(props: ImageClipProps) {
  const [blobURL, setBlobURL] = React.useState<string | null>(null);
  const [showImageOpen, setShowImageOpen] = React.useState<boolean>(false);

  const handleClick = () => {
    setShowImageOpen(true);
  };

  React.useEffect(() => {
    if (!props.imageBlob) {
      return;
    }

    const blobUrl = URL.createObjectURL(props.imageBlob);
    setBlobURL(blobUrl);
    return () => {
      URL.revokeObjectURL(blobUrl);
    }
  }, [props.imageBlob]);

  React.useEffect(() => {
    if (!props.imageURL) {
      return;
    }

    setBlobURL(props.imageURL);
  }, [props.imageURL]);

  if (!props.imageBlob && !props.imageURL) {
    console.error("No imageBlob or imageURL provided");
    return null;
  }

  if (!blobURL) {
    return null;
  }

  return (
    <React.Fragment>
      <ShowImage
        imageURL={props.imageURL} imageBlob={props.imageBlob}
        dialogOpen={showImageOpen} setDialogOpen={setShowImageOpen} />
      <Chip
        avatar={<Avatar alt={props.text} src={blobURL} />}
        label={props.text}
        onClick={handleClick}
        onDelete={props.onDeleted}
        style={{
          margin: '0.25rem',
        }}
      />
    </React.Fragment>
  );
}

export default ImageClip;
