import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { List, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Index } from "../utils/indexes";

export interface SelectIndexProps {
  selected?: string;
  availableIndexes: Index[];
  open: boolean;
  onClose: (index?: Index) => void;
}

function SelectIndex(props: SelectIndexProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const annotation = formJson.annotation;
            props.onClose(annotation);
          },
        }}
      >
        <DialogTitle>{t('Select Index to Show')}</DialogTitle>
        <DialogContent>
          <List>
            {props.availableIndexes.map((index) => {
              const style = {
                backgroundColor: props.selected === index.id ? 'primary' : 'transparent',
              };

              return (
                <ListItemButton key={index.id} onClick={() => props.onClose(index)} style={style} selected={props.selected === index.id}>
                  <ListItemText primary={t(index.name) + (index.description ? ' - ' + t(index.description) : '')} />
                </ListItemButton>
              )})}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.onClose()}}>{t('Cancel')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default SelectIndex;
