import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useTranslation } from "react-i18next";
import Rename from "../common/dialogs/Rename";
import { gql, useMutation } from "@apollo/client";

export interface FieldMenuProps {
  uuid: string;
  rename?: (name: string | undefined | null) => void;
  buttonStyle?: React.CSSProperties;
}

function FieldMenu(props: FieldMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [ renameDialogOpen, setRenameDialogOpen ] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };
  const handleClose = (event: React.MouseEvent) => {
    setAnchorEl(null);
    event.stopPropagation();
    event.preventDefault();
  };

  const [ renameField ] = useMutation(gql(`
    mutation RenameField($uuid: uuid!, $name: String!) {
      update_fields(where: {uuid: {_eq: $uuid}}, _set: {name: $name}) {
        returning {
          name
          uuid
        }
      }
    }
  `));

  const rename = (name: string | undefined | null) => {
    if (!name) {
      return;
    }

    renameField({
      variables: {
        uuid: props.uuid,
        name: name,
      },
    }).
      then((result) => {
        if (props.rename) {
          props.rename(result.data.update_fields.returning[0].name || null);
        }
      }).
      catch((e) => {
        console.error("Failed to rename field", e);
      }
    );

    if (props.rename) {
      props.rename(name);
    }
  }

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <IconButton edge="end" onClick={handleClick}>
        <MoreVertIcon style={props.buttonStyle} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setRenameDialogOpen(true);
          handleClose(event);
        }}>
          <ListItemIcon>
            <DriveFileRenameOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Rename')}</ListItemText>
        </MenuItem>
      </Menu>
      <Rename open={renameDialogOpen} onClose={(name) => {
        setRenameDialogOpen(false);
        if (name) {
          rename(name);
        }
      } } />
    </React.Fragment>
  );
}

export default FieldMenu;
