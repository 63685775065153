import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const primary = '#000000';
export const secondary = '#FFAA00';
export const gray = '#CAC9C9';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: red['500'],
    }
  },
});

export default theme;
