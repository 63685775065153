import React from "react";
import FieldsSummary from "../components/FieldsSummary";
import FieldsGallery from "../components/FieldsGallery";
import RecentMappings from "../components/RecentMappings";
import { withAuthenticationRequired } from "react-oidc-context";
import { gql, useQuery } from "@apollo/client";
import { OrganizationContext } from "../common/contexts/organization";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import { Grid } from "@mui/material";
import Loading from "../common/components/Loading";

function Home() {
  const [ nFields, setNFields ] = React.useState<number>(0);
  const [ fields, setFields ] = React.useState<any[]>([]);
  const [ recentAnalyses, setRecentAnalyses ] = React.useState<any[]>([]);

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query Home {
      fields_aggregate(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`) {
        aggregate {
          count
        }
      }
      fields(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}) {
        uuid
        name
      }
      analyses(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}, limit: 10) {
        uuid
        state
        created_at
        field {
          uuid
          name
        }
        agriculture_analysis_data {
          orthophoto_png
        }
      }
    }
  `));

  React.useEffect(() => {
    if (data) {
      setNFields(data.fields_aggregate.aggregate.count);
      setFields(data.fields);
      setRecentAnalyses(data.analyses);
    }
  }, [data]);

  if (loading) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FieldsSummary nFields={nFields} />
          <FieldsGallery fields={fields} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentMappings analysis={recentAnalyses} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withAuthenticationRequired(Home);
