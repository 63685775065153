import { useMap } from "@vis.gl/react-google-maps";
import React from "react";
import Loading from "./Loading";

export interface PNGLayerProps extends React.PropsWithChildren<{}> {
  blob?: Blob;
  bounds?: google.maps.LatLngBounds;
  height?: number | string;
  top?: number | string;
}

function PNGLayer(props: PNGLayerProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [groundOverlay, setGroundOverlay] = React.useState<google.maps.GroundOverlay | null>(null);

  const map = useMap();

  React.useEffect(() => {
    if (!map || !props.bounds || !props.blob) {
      return;
    }

    if (groundOverlay) {
      groundOverlay.setMap(null);
    }

    const overlay = new google.maps.GroundOverlay(
      URL.createObjectURL(props.blob),
      props.bounds,
      {
        opacity: 0.7,
        clickable: false,
      }
    );
    overlay.setMap(map);
    setGroundOverlay(overlay);
    setLoading(false);
  }, [map, props.bounds, props.blob]);

  React.useEffect(() => {
    return () => {
      if (groundOverlay) {
        groundOverlay.setMap(null);
      }
    };
  }, [groundOverlay]);

  return (
    <React.Fragment>
      <Loading open={loading} height={props.height} top={props.top} />
    </React.Fragment>
  );
}

export default PNGLayer;
