import React from "react";
import { withAuthenticationRequired } from "react-oidc-context";
import MapFull from "../common/components/MapFull";
import UserPosition from "../common/components/UserPosition";
import BackButton from "../common/components/BackButton";
import FloatingTopBar from "../components/FloatingTopBar";
import Annotate from "../common/dialogs/Annotate";
import { Annotation, Attachment } from "../common/classes/annotation";
import AnnotationMarker from "../common/components/AnnotationMarker";
import TimeLine from "../components/TimeLine";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { computeZoom } from "../common/utils/position";
import Loading from "../common/components/Loading";
import { and, whereNotDeleted } from "../common/utils/graphql";
import AnalysisLayer from "../common/components/AnalysisLayer";
import { db } from "../common/db/db";
import IndexSlider from "../common/components/IndexSlider";
import { getIndex, globalAvailableIndexes } from "../utils/indexes";
import Viewer3D from "../common/components/Viewer3D";
import { Fab } from "@mui/material";
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Measure from "../components/Measure";

function FieldDetails() {
  const [ measuring, setMeasuring ] = React.useState<boolean>(false);
  const [ measurePoints, setMeasurePoints ] = React.useState<google.maps.LatLngLiteral[]>([]);
  const [ latLngs, setLatLngs ] = React.useState<google.maps.LatLngLiteral[]>([]);
  const [ selectedRange, setSelectedRange ] = React.useState<[number, number] | undefined>(undefined);
  const [ min, setMin ] = React.useState<number | undefined>(undefined);
  const [ max, setMax ] = React.useState<number | undefined>(undefined);
  const [ annotateDialogOpen, setAnnotateDialogOpen ] = React.useState<boolean>(false);
  const [ clickLatLng, setClickLatLng ] = React.useState<google.maps.LatLngLiteral | null>(null);
  const [ annotations, setAnnotations ] = React.useState<Annotation[]>([]);
  const [ availableIndexes, setAvailableIndexes ] = React.useState<string[]>([]);
  const [ selectedIndex, setSelectedIndex ] = React.useState<string | undefined>();
  const [ currentAnalysis, setCurrentAnalysis ] = React.useState<{
    uuid: string,
    created_at: string,
  } | null>(null);
  const [ analysis, setAnalysis ] = React.useState<{
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      orthophoto_png: string,
    },
  }[]>([]);
  const [ field, setField ] = React.useState<{
    id: number,
    uuid: string,
    name: string,
  } | null>(null);
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();

  const url = new URL(window.location.href);
  const analysisUUID = url.searchParams.get('analysis');
  const indexName = url.searchParams.get('index');

  if (indexName && !selectedIndex) {
    setSelectedIndex(indexName);
  }

  const { fieldId } = useParams();

  const { loading, error, data } = useQuery(gql(`
    query FieldDetails($fieldId: uuid!) {
      fields(where: {uuid: {_eq: $fieldId}}) {
        id
        uuid
        name
        analyses(where: `+and(whereNotDeleted() + '{type: {_eq: "agriculture"}}')+`, order_by: {created_at: desc}) {
          uuid
          created_at
          agriculture_analysis_data {
            orthophoto
            orthophoto_png
            index_data(order_by: {name: asc}) {
              name
              png
              tiff
            }
          }
        }
        lat_lngs(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          lat
          lng
        }
        annotations(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          uuid
          created_at
          annotation
          position(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
            lat
            lng
          }
          attachments(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
            uuid
            created_at
            file_name
            file_size
            file_type
          }
        }
      }
    }
  `), {
    variables: {
      fieldId,
    }
  });

  const [ deleteAnnotation ] = useMutation(gql(`
    mutation DeleteAnnotation($uuid: uuid!) {
      update_annotations(where: {uuid: {_eq: $uuid}}, _set: {deleted_at: "now()"}) {
        affected_rows
      }
    }
  `));

  React.useEffect(() => {
    setSelectedRange(undefined);
  }, [selectedIndex]);

  React.useEffect(() => {
    if (!measuring) {
      setMeasurePoints([]);
    }
  }, [measuring]);

  React.useEffect(() => {
    if (data) {
      setField(data.fields[0]);
      setAnnotations(data.fields[0].annotations.filter((a: any) => a.position.length > 0).map((a: any) => {
        return {
          uuid: a.uuid,
          date: new Date(a.created_at),
          position: { lat: a.position[0].lat, lng: a.position[0].lng },
          annotation: a.annotation,
          audioBlobs: [],
          pictureBlobs: [],
          attachments: a.attachments.map((att: any) => {
            return {
              uuid: att.uuid,
              createdAt: new Date(att.created_at),
              fileName: att.file_name,
              contentType: att.file_type,
            }
          }).sort((a: Attachment, b: Attachment) => a.createdAt.getTime() - b.createdAt.getTime()),
        } as Annotation;
      }));
      setAnalysis(data.fields[0].analyses);
      let currAnalysis: any | null;
      if (!currentAnalysis) {
        currAnalysis = data.fields[0].analyses.find((a: any) => a.uuid === analysisUUID) ?? data.fields[0].analyses[0];
        setCurrentAnalysis(currAnalysis);
        if (currAnalysis) {
          const url = new URL(window.location.href);
          url.searchParams.set('analysis', currAnalysis.uuid);
          window.history.replaceState({}, document.title, url.toString());
        }
      } else {
        currAnalysis = currentAnalysis;
      }

      const b = new google.maps.LatLngBounds();
      const tmpLatLngs: google.maps.LatLngLiteral[] = [];
      data.fields[0].lat_lngs.forEach((latLng: any) => {
        b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
        tmpLatLngs.push({ lat: latLng.lat, lng: latLng.lng });
      });
      setDefaultCenter(b.getCenter().toJSON());
      setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
      setLatLngs(tmpLatLngs);

      db.fields.put({
        id: data.fields[0].uuid,
        bounds: b.toJSON(),
      }).then(() => {});

      const aIdx = [];
      if (data.fields[0].analyses.length > 0) {
        const tmp = currAnalysis.agriculture_analysis_data;
        if (tmp && tmp.length > 0 && (tmp[0].orthophoto || tmp[0].orthophoto_png)) {
          aIdx.push('Orthophoto');
        }
        if (tmp && tmp.length > 0 && tmp[0].index_data) {
          for (const idx of tmp[0].index_data) {
            aIdx.push(idx.name.toUpperCase());
          }
        }
      }
      setAvailableIndexes(aIdx);
    } else {
      setLatLngs([]);
      setField(null);
      setAnalysis([]);
      setCurrentAnalysis(null);
      setAvailableIndexes([]);
      setDefaultCenter(undefined);
      setDefaultZoom(undefined);
    }
  }, [data, currentAnalysis, analysisUUID]);

  const onAnnotateDialogClose = (annotation: Annotation | undefined) => {
    if (annotation && clickLatLng) {
      annotation.uuid = annotations.length+'';
      annotation.position = clickLatLng;
      annotation.date = new Date();
      setAnnotations([...annotations, annotation]);
    }
    setAnnotateDialogOpen(false);
  }

  const onBoundsChange = (bounds: google.maps.LatLngBounds) => {
    if (!currentAnalysis) {
      return;
    }

    db.analyses.put({
      id: currentAnalysis.uuid,
      bounds: bounds.toJSON(),
    }).then(() => {});
  }

  if (!defaultCenter) {
    return (
      <Loading open />
    );
  }

  return (
    <MapFull
    defaultCenter={defaultCenter}
    defaultZoom={defaultZoom}
    onClick={(e) => {
      switch (measuring) {
        case true:
          if (e.detail.latLng) {
            setMeasurePoints([...measurePoints, e.detail.latLng]);
          }
          break;
        case false:
          setClickLatLng(e.detail.latLng);
          setAnnotateDialogOpen(true);
          break;
      }
    }}>
      { /* <Polygon path={latLngs} zIndex={-100} /> */ }
      { globalAvailableIndexes.map((gIndex) => {
        // TODO
        if (!currentAnalysis || selectedIndex !== gIndex.id || !gIndex.imageKey) {
          return null;
        }
        if (gIndex.id === 'Orthophoto') {
          return (
            <AnalysisLayer key={gIndex.id}
              analysisID={currentAnalysis.uuid}
              image={gIndex.imageKey}
              onBoundsChange={onBoundsChange}
              rgb
              reversed />
          )
        }
        if (gIndex.id === 'GLB') {
          return (
            <Viewer3D key={gIndex.id}
              analysisID={currentAnalysis.uuid}
              image={gIndex.imageKey} />
          )
        }
        if (gIndex.id === 'None') {
          return null;
        }
        return (
          <AnalysisLayer key={gIndex.id}
            analysisID={currentAnalysis.uuid}
            image={gIndex.imageKey}
            onBoundsChange={onBoundsChange}
            setMin={setMin} setMax={setMax}
            clipMin={0}
            range={selectedRange}
            reversed />
        )
      }) }
      { /* <Polyline path={latLngs} zIndex={100} closed /> */ }
      <UserPosition />
      <FloatingTopBar
        selectedIndex={getIndex(selectedIndex)}
        availableIndexes={availableIndexes}
        onIndexChange={(index) => {
          setSelectedIndex(index.id);
          if (index && index.id !== 'GLB') {
            const url = new URL(window.location.href);
            url.searchParams.set('index', index.id);
            window.history.replaceState({}, document.title, url.toString());
          }
        }}
        currentAnalysisUUID={currentAnalysis?.uuid}
        currentIndex={selectedIndex ?? undefined}
        showCompare />
      <BackButton />
      <Annotate open={annotateDialogOpen} onClose={onAnnotateDialogClose} latLng={clickLatLng} fieldID={field?.id ?? 0} />
      { annotations.map((annotation) => (
        <AnnotationMarker key={annotation.uuid} annotation={annotation} onDelete={() => {
          deleteAnnotation({
            variables: {
              uuid: annotation.uuid,
            }
          }).then(() => {
            setAnnotations(annotations.filter((a) => a.uuid !== annotation.uuid));
          }).catch((e) => {
            console.error("Failed to delete annotation", e);
          });
        }} />
      ))}
      <Measure positions={measurePoints} />
      { selectedIndex !== 'Orthophoto' && selectedIndex !== 'GLB' && selectedIndex !== 'None' && <IndexSlider min={min} max={max} onSelectedRange={(range) => {
        setSelectedRange(range);
      }} reversed /> }
      <TimeLine
        analysis={analysis}
        currentAnalysis={currentAnalysis ?? undefined}
        onAnalysisSelected={(analysisUuid) => {
          setCurrentAnalysis(analysis.find((a) => a.uuid === analysisUuid) ?? null);
          if (analysisUuid) {
            const url = new URL(window.location.href);
            url.searchParams.set('analysis', analysisUuid);
            window.history.replaceState({}, document.title, url.toString());
          }
        }} />
        <Fab
        onClick={() => {
          setMeasuring(!measuring);
        }}
        style={{
          position: 'absolute',
          bottom: '90px',
          right: '10px',
          zIndex: 0,
        }} color={ measuring ? 'secondary' : undefined }>
          <SquareFootIcon />
        </Fab>
    </MapFull>
  );
}

export default withAuthenticationRequired(FieldDetails);
