import React from "react";
import { useTranslation } from "react-i18next";
import { Attachment } from "../classes/annotation";
import AudioClip from "./AudioClip";
import ImageClip from "./ImageClip";
import useApi from "../hooks/useApi";

export interface AttachmentProps {
  attachment: Attachment;
  index: number;
}

function AttachmentChip(props: AttachmentProps) {
  const [ url, setURL ] = React.useState<string | null>(null);

  const { attachment, index } = props;

  const { get } = useApi();

  const { t } = useTranslation();

  React.useEffect(() => {
    get('/attachments/' + attachment.uuid).then((response: any) => {
      setURL(response.url);
    });
  }, [attachment.uuid]);

  if (!url) {
    return null;
  }

  if (attachment.contentType.startsWith('audio/')) {
    return <AudioClip key={index} audioURL={url} text={t('Audio') + (index+1)} />;
  } else if (attachment.contentType.startsWith('image/')) {
    return <ImageClip key={index} imageURL={url} text={t('Picture') + (index+1)} />;
  } else {
    return null;
  }
}

export default AttachmentChip;
