import { Button, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useEmbed from "../common/hooks/useEmbed";

export interface FieldsSummaryProps {
  nFields: number;
}

function FieldsSummary(props: FieldsSummaryProps) {
  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  const { t } = useTranslation();

  return (
    <Paper elevation={0} style={{
      margin: 20,
      padding: 20,
      backgroundColor: 'var(--primary)',
      color: 'var(--white)',
      borderRadius: 20,
    }}>
      <div>{t('Number of inspections')}</div>
      <div style={{
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <span style={{
          fontSize: 30,
        }}>{ props.nFields }</span>
        <span>
          <Button variant="contained" disableElevation
          onClick={() => {
            navigate(appendParams('/app/inspections'));
          }}
          style={{
            backgroundColor: 'var(--white)',
            color: 'var(--primary)',
            height: 40,
            borderRadius: 15,
          }}>{t('See inspections')}</Button>
        </span>
      </div>
    </Paper>
  );
}

export default FieldsSummary;
