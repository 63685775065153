import { CircularProgress, Paper, Typography } from "@mui/material";
import React from "react";
import useCache, { IMAGE_ORTHOPHOTO, IMAGE_SIZE_THUMBNAIL } from "../hooks/useCache";

export interface TimeLineCardProps {
  onClick?: () => void;
  analysis: {
    uuid: string;
    created_at: string;
  };
}

function TimeLineCard(props: TimeLineCardProps) {
  const [ imageURL, setImageURL ] = React.useState<string | undefined>();

  const { cacheInitialized, getPresignedUrl, progress } = useCache();

  React.useEffect(() => {
    if (cacheInitialized) {
      if (props.analysis) {
        getPresignedUrl(props.analysis.uuid, IMAGE_ORTHOPHOTO, IMAGE_SIZE_THUMBNAIL).then((url) => {
          setImageURL(url);
        }).catch((e) => {
          console.error("Failed to download presigned image", e);
        });
      }
    }
  }, [cacheInitialized]);

  return (
    <div>
      <Paper
      elevation={0}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      style={{
        margin: 5,
        padding: 10,
        backgroundColor: 'var(--gray)',
        color: 'var(--white)',
        borderRadius: 20,
        height: 120,
        textAlign: 'center',
        alignContent: 'center',
        cursor: 'pointer',
      }}>
        { !imageURL && <div style={{
          borderRadius: 10,
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CircularProgress size={40}
            variant={progress > 0 ? 'determinate' : 'indeterminate'}
            value={progress > 0 ? progress * 100 : undefined} />
        </div>}
        { imageURL && <img src={imageURL} style={{
          width: 'auto',
          height: 'auto',
          maxWidth: 100,
          maxHeight: 100,
          objectFit: 'cover',
          backgroundColor: 'transparent',
        }} /> }
      </Paper>
      <Typography variant="caption" component="div" style={{
        width: 100,
        textAlign: 'center',
      }}>{new Date(props.analysis.created_at).toLocaleDateString()}</Typography>
    </div>
  );
}

export default TimeLineCard;
