import { BottomNavigation, BottomNavigationAction, Paper, styled } from "@mui/material";
import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useLocation } from "react-router-dom";
import { isPWA } from '../common/utils/displayMode';
import { iOSPWABottomBarHeight, iOSPWABottomPadding } from '../config';
import { isiOS } from '../common/utils/device';
import { useTranslation } from "react-i18next";

function BottomBar() {
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  const paddingBottomNumber = isPWA() && isiOS() ? iOSPWABottomPadding : 0;
  const paddingBottom = paddingBottomNumber + 'px';

  const [value, setValue] = React.useState(0);

  const { t } = useTranslation();

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.startsWith('/app/home')) {
      setValue(0);
    } else if (location.pathname.startsWith('/app/inspections')) {
      setValue(1);
    } else if (location.pathname.startsWith('/app/user')) {
      setValue(2);
    } else {
      setValue(0);
    }
  }, [location]);

  return (
    <React.Fragment>
      <Offset style={
        {
          height: paddingBottomNumber > 0 ? (paddingBottomNumber+iOSPWABottomBarHeight)+'px' : undefined,
        }
      } />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} style={
        {
          paddingBottom,
        }
      }>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={Link} to="/app/home" label={t("Home")} icon={<HomeIcon />} />
          <BottomNavigationAction component={Link} to="/app/inspections" label={t("Inspections")} icon={<SearchIcon />} />
          <BottomNavigationAction component={Link} to="/app/user" label={t("User")} icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </React.Fragment>
  );
}

export default BottomBar;
